import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GETLASTMONTH, GETTHISMONTH} from "../graphql/queries";
import Title from "./Title";
import {Link, Typography} from "@mui/material";


function preventDefault(event) {
    event.preventDefault();
}
function LastMonth()
{

    const { data: dataOne, loading: loadingOne, error: errorOne } = useQuery(GETTHISMONTH);
    const { data: dataTwo, loading: loadingTwo, error: errorTwo } = useQuery(GETLASTMONTH);

    if (loadingOne || loadingTwo) return <p>Loading...</p>;
    if (errorOne || errorTwo) return <p>Error</p>;
    const { getThisMonth } = dataOne;
// Compter les occurrences de chaque sellingPoint
    let sellingPointCounts = {};
    getThisMonth.forEach(item => {
        let sellingPointName = item.sellingPoint.name;
        sellingPointCounts[sellingPointName] = (sellingPointCounts[sellingPointName] || 0) + 1;
    });

// Trouver le sellingPoint avec le plus de projets
    let mostCommonSellingPoint = null;
    let maxCount = 0;
    for (let sellingPoint in sellingPointCounts) {
        if (sellingPointCounts[sellingPoint] > maxCount) {
            mostCommonSellingPoint = sellingPoint;
            maxCount = sellingPointCounts[sellingPoint];
        }
    }

    const { getLastMonth} = dataTwo
    return (
        <React.Fragment>
            <Title>Projets ce mois-ci</Title>
            <Typography component="p" variant="h4">
                {getThisMonth.length}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
                Dernier mois : {getLastMonth.length}
            </Typography>
            <div>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                    {mostCommonSellingPoint} à le plus de projet avec {maxCount}
            </Typography>
            </div>
        </React.Fragment>
    )
}

export default LastMonth