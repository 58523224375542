import {createTheme, ThemeProvider, useTheme} from "@mui/material/styles";
import {useLazyQuery} from "@apollo/client";
import {BOULANGER} from "../graphql/queries";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Title from "./Title";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Grid";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {BarChart} from "@mui/x-charts";

// Fonction pour compter les projets par utilisateur
function countProjectsByUser(sellingPoints) {
    const userProjectCounts = {};
  
    // Parcourir chaque sellingPoint
    sellingPoints.forEach(sellingPoint => {
      // Parcourir chaque projet
      sellingPoint.projects.forEach(project => {
        const { user } = project;
        const name = sellingPoint.name + "-" + user.firstname + " " + user.surname
        // Si l'utilisateur n'existe pas dans le compteur, l'initialiser à 0
        if (!userProjectCounts[name]) {
          userProjectCounts[name] = 0;
        }
        // Incrémenter le compteur pour l'utilisateur
        userProjectCounts[name]++;
      });
    });
  
  // Créer un tableau d'objets à partir des comptages
  const userProjectsArray = Object.keys(userProjectCounts).map(user => {
    return { user: user, projectCount: userProjectCounts[user] };
  });

  return userProjectsArray;
  }


const chartSetting = {
    xAxis: [
        {
            label: '',
        },
    ],
    width: 600,
    height: 400,
};
const defaultTheme = createTheme({
    palette: {
        primary: {
            main:'#D34503'
        },
    },
});
export default function MyBarChartUser() {
    const [fetchTableData, { loading, error, data }] = useLazyQuery(BOULANGER);

    const [startDate, setStartDate] = useState(moment().startOf('month'))
    const [endDate, setEndDate] = useState(moment())



    useEffect(() => {
        fetchTableData({});
    }, [startDate, fetchTableData, endDate]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;


    let sellingPoints = data ? data.boulanger.sellingPoints : null;
  
    if(sellingPoints === null)
        return <p>Loading...</p>;

// Filtre les projets pour chaque sellingPoint
    const filteredSellingPoints = sellingPoints.map(sellingPoint => ({
        ...sellingPoint,
        projects: sellingPoint.projects.filter(project => {
            const projectDate = new Date(project.createdAt);
            return projectDate >= startDate.toDate() && projectDate <= endDate.toDate();
        })
    }));

    // Appeler la fonction et afficher le résultat
    const bla = countProjectsByUser(filteredSellingPoints);
    bla.sort((a, b) =>  a.projectCount - b.projectCount);
bla.reverse()
    return (
        <ThemeProvider theme={defaultTheme}>
            <Title>Evolution</Title>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de début"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de fin"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <div style={{width: '100%', flexGrow: 1}}>
                {
                    bla.length <=0 ? "":
                        <BarChart
                            margin={{left:150}}
                            dataset={bla}
                            yAxis={[{ scaleType: 'band', dataKey: 'user'}]}
                            series={[{ dataKey: 'projectCount', label: 'Projets créé' }]}
                            layout="horizontal"
                            {...chartSetting}
                        />
                }
            </div>
        </ThemeProvider>
)

}