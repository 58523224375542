import './App.css';
import React from "react";
import Boulanger from "./views/boulanger.js";
import Header from './Header';
import CreateLink from './CreateLink';

import { Router, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoote from "./components/PrivateRoote";
import SignIn from "./views/SignIn";
import NoView from "./views/NoView";
//import SignIn from "./views/SignIn";



/*const fakeAuth = {
  isAuthenticated: true,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};*/

 
/*const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      fakeAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Navigate to="/" />
      )
    }
  />
);*/

const App = () => {
  return (
    <div className="center w85">
      <div className="ph3 pv1 background-gray">

        <Routes>

          <Route path="/" element={<SignIn/>}>
               </Route>
            <Route path="icon" element={<PrivateRoote Component={Boulanger} />} />
            <Route path="boulanger" element={<Boulanger />} />
            <Route path="Noview" element={<NoView></NoView>}/>
        <Route path="*" element={<SignIn/>}/>
        </Routes>
      </div>
    </div>
  );
};

export default App;
