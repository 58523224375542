import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Grid";
import {BarChart} from "@mui/x-charts";
import {useLazyQuery} from "@apollo/client";
import {BOULANGER} from "../graphql/queries";
import {createTheme} from "@mui/material/styles";

const chartSetting = {
    xAxis: [
        {
            label: '',
        },
    ],
    height: 400,
};
const defaultTheme = createTheme({
    palette: {
        primary: {
            main:'#D34503'
        },
    },
});

function countProjectsBySellingPoint(sellingPoints)
{
    let bla = sellingPoints.map((selling) => ({

        user : selling.name,
        projectCount : selling.projects.length
    }))
    return bla;
}
function countProjectsByUser(sellingPoints) {
    const userProjectCounts = {};

    // Parcourir chaque sellingPoint
    sellingPoints.forEach(sellingPoint => {
        // Parcourir chaque projet
        sellingPoint.projects.forEach(project => {
            const { user } = project;
            const name = sellingPoint.name + " - "  + user.surname
            // Si l'utilisateur n'existe pas dans le compteur, l'initialiser à 0
            if (!userProjectCounts[name]) {
                userProjectCounts[name] = 0;
            }
            // Incrémenter le compteur pour l'utilisateur
            userProjectCounts[name]++;
        });
    });

    // Créer un tableau d'objets à partir des comptages
    const userProjectsArray = Object.keys(userProjectCounts).map(user => {
        return { user: user, projectCount: userProjectCounts[user] };
    });

    return userProjectsArray;
}
function CustomTabPanel(props) {
    const { children, value, index, func, filteredData, ...other } = props;
    const bla = func(filteredData);
    bla.sort((a, b) =>  a.projectCount - b.projectCount);
    bla.reverse()
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div style={{width: '100%', flexGrow: 1}}>
                        {
                            bla.length <= 0 ? "" :
                                <BarChart
                                    margin={{left: 200}}
                                    dataset={bla}
                                     yAxis={[{scaleType: 'band', dataKey: 'user'}]}
                                    series={[{dataKey: 'projectCount', label: 'Projets créé'}]}
                                    layout="horizontal"
                                    {...chartSetting}
                                />
                        }
                    </div>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    filteredData : PropTypes.array.isRequired,
    func: PropTypes.func.isRequired,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [fetchTableData, { loading, error, data }] = useLazyQuery(BOULANGER);

    const [value, setValue] = React.useState(0);
    const [startDate, setStartDate] = useState(moment().startOf('month'))
    const [endDate, setEndDate] = useState(moment())

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchTableData({});
    }, [startDate, fetchTableData, endDate]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    let sellingPoints = data ? data.boulanger.sellingPoints : null;

    if(sellingPoints === null)
        return <p>Loading...</p>;

// Filtre les projets pour chaque sellingPoint
    const filteredSellingPoints = sellingPoints.map(sellingPoint => ({
        ...sellingPoint,
        projects: sellingPoint.projects.filter(project => {
            const projectDate = new Date(project.createdAt);
            return projectDate >= startDate.toDate() && projectDate <= endDate.toDate();
        })
    }));
     return (
        <Box sx={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de début"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de fin"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Par utilisateur" {...a11yProps(0)} />
                    <Tab label="Par point de vente" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} func={countProjectsByUser} filteredData={filteredSellingPoints}>
                Item One
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} func={countProjectsBySellingPoint} filteredData={filteredSellingPoints}>
                Item One
            </CustomTabPanel>
        </Box>
    );
}