import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {BOULANGER, CURRENT_USER_QUERY} from '../graphql/queries.js';
import React, {useEffect, useState} from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
    Paper,
    Container,
    Typography, Avatar
} from '@mui/material';
import Grid from "@mui/material/Grid";
import PDVList from "./PDVList";
import Chart from "./Chart";
import LastMonth from "./LastMonth"
import {createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {LOGOUT} from "../graphql/mutation";
import MyBarChart from "./MyBarChart";
import MyBarChartUser from './MyBarChartUser.js';
import CustomTabPanel from "./BasicTabs";
import BasicTabs from "./BasicTabs";



const card = (
    <React.Fragment>
         <CardContent>
         <LineChart
  xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
  series={[
    {
      data: [2, 5.5, 2, 8.5, 1.5, 5],
    },
  ]}
  width={500}
  height={300}
/>
         </CardContent>
    </React.Fragment>
)


const defaultTheme = createTheme({
    palette: {
        primary: {
            main:'#D34503'
        },
    },
});

const myTheme = createTheme();

function Getboulanger() {
    const { loading, error, data } = useQuery(BOULANGER);
    if (loading) return `Error! ${error}`;
    if (error) return `Error! ${error}`;
    const { company } = data;
    let cc = company.sellingPoints.reduce((acc, current_value) => {
        let transformedProjects = current_value.projects.map(project => {
            return {
                ...project,
                createdAt: project.createdAt.split('T')[0]  // Conversion de la date au format YYYY-MM-DD
            };
        });
        return acc.concat(transformedProjects);
    },[])
    const dateCounts = cc.reduce((acc, { createdAt }) => {
        acc[createdAt] = (acc[createdAt] || 0) + 1;
        return acc;
    }, {});
    
    const result = Object.keys(dateCounts).map(date => ({
        CreateAt: new Date(date),
        count: dateCounts[date]
    }));
    result.sort((a, b) => new Date(a.CreateAt) - new Date(b.CreateAt));
    return (
        <div>
            <h1>Project Count</h1>
            <h3>{company.sellingPoints.length}</h3>
            <LineChart
                xAxis={[
                    {
                        dataKey: 'CreateAt',
                        scaleType: 'time',
                        min: new Date('2024-01-15T03:24:00'),
                        max: Date.now()
                    }]}
                series={[
                    {
                        dataKey:'count',
                    },

                ]}
                width={500}
                height={300}
                dataset={result}
            />
            <Box sx={{ minWidth: 275 }}>
                <Card variant="outlined">{card}</Card>
            </Box>
            

        </div>
    )
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
})
function Authen()
{
    const navigate = useNavigate();
    const [fetchTableData, { data, loading, error }] = useLazyQuery(CURRENT_USER_QUERY);
    const [date, setDate] = useState(null)
    useEffect(() => {
        fetchTableData({}).then(r => {
            if(r.data.currentUser == null)
                navigate("/");
            else if (r.data.currentUser.user.sellingPoint.company.name !== "Boulanger")
                navigate("/Noview")
        });
    }, [date, fetchTableData]);

    return <h1></h1>
}

function IsAuth()
{
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(CURRENT_USER_QUERY);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if(data.currentUser == null)
        navigate("/");
    else if (data.currentUser.user.sellingPoint.company.name !== "Boulanger")
        navigate("/Noview")
    return <h1></h1>
}
function Logout() {

    let history = useNavigate();
    const [logout] = useMutation(LOGOUT, { refetchQueries: [{ query: CURRENT_USER_QUERY }], onCompleted(data){
            history(`/`)
        }});
    return (
        <Button variant="contained" onClick={() => logout()}>Se Déconnecter</Button>
    )
}

class Dashboard extends React.Component {

    render() {


        const { classes, theme } = this.props;


        return (
            <ThemeProvider theme={defaultTheme}>
                <Authen></Authen>
                <AppBar position="absolute" color="primary" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Boulanger Dashboard
                        </Typography>

                            <Avatar
                                alt="Remy Sharp"
                                src="1786x1786.jpg"
                                sx={{ width: 56, height: 56 }}

                            />
                    <Logout></Logout>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
            <div>
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={9}>

                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 500,
                                    }}
                                >
                                    <BasicTabs />
                                </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                            >
                                <LastMonth>

                                </LastMonth>
                            </Paper>
                        </Grid>
                       <Grid item xs={12}>
                           <Paper sx={{
                               p: 2,
                               display: 'flex',
                               flexDirection: 'column',
                               height: 840,
                           }}>
                               <PDVList></PDVList>
                           </Paper>

                       </Grid>

{/*                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            width={500}
                            height={300}
                        />*/}
                    </Grid>

                </Container>
</div>
                </Box>
                </Box>
            </ThemeProvider>
        )
    }
}

export default (Dashboard)