import { gql, useMutation } from '@apollo/client';

export const ADD_SUPPORT = gql`
  mutation addMessageSupport($userId: Int!, $message: String!, $object: String!){
    addMessageSupport(UserId: $userId, message: $message, object: $object){
      id
    }
  }
`;

export const LOGIN = gql`
  mutation login($mail: String!, $password: String!) {
    login(mail: $mail, password: $password) {
      user {
        id
          password
          role {
            name
          }
      }
    }
  }
`;

export const LOGOUT = gql`
mutation logout {
  logout 
}
`;

export const UPDATE_LICENCE = gql`
mutation updateProject($id:Int!, $StateId:Int!) {
  updateProject(id:$id, StateId:$StateId) {
    name
  }
}
`;


export const ADD_COMPANY = gql`
mutation createCompany($name:String!) {
  createCompany(name:$name) {
    name
    }
  }
`;

export const ADD_HEADSET = gql`
mutation addHeadset($serialNumber:String!, $LicenceId:Int!) {
  createHeadset(serialNumber:$serialNumber, LicenceId:$LicenceId)
  {serialNumber}}
`;
export const ADD_LICENCE = gql`
mutation createLicence($name:String!, $SellingPointId:Int!) {
  createLicence(name:$name, SellingPointId:$SellingPointId) {
    name
    }
    }
`;

export const ADD_PDV = gql`
mutation createSellingPoint($CompanyId:Int!, $name:String!) {
  createSellingPoint(CompanyId:$CompanyId, name:$name) {
    name
    }
  }
`;

export const ADD_USER = gql`
mutation createUser($mail:String!, $password:String!, $firstname:String!, $surname:String!, $SellingPointId:Int!, $phone:String, $post:String) {
  createUser(mail:$mail, password:$password, firstname:$firstname, surname:$surname, SellingPointId:$SellingPointId, phone:$phone, post:$post) {
    id
    account {
      mail
    }
    firstname
    surname
    }
  }
`;

export const EDIT_USER = gql`
mutation editUser($id:Int!, $firstname:String!, $surname:String!,$phone:String, $post:String, $mail:String) {
  editUser(id:$id, firstname:$firstname, surname:$surname, phone:$phone, post:$post, mail:$mail) {
    id
    account {
      mail
    }
    firstname
    surname
    }
  }
`;

export const CHANGE_PASSWORD = gql`
mutation changePassword($id:Int!, $password:String!) {
  changePassword(id:$id, password:$password) {
    id
  }
}
`;

export const DELETE_USER = gql`
mutation deleteUser($id:Int!) {
  deleteUser(id:$id) 
}
`;
/* export const DELETE_HEADSET = gql`
mutation deleteHeadset($serialNumber:String!) {
  deleteHeadset(serialNumber:$serialNumber)
}
`; */
