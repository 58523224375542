import {createTheme, ThemeProvider, useTheme} from "@mui/material/styles";
import {useLazyQuery} from "@apollo/client";
import {BOULANGER} from "../graphql/queries";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Title from "./Title";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Grid";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {BarChart} from "@mui/x-charts";

const chartSetting = {
    xAxis: [
        {
            label: '',
        },
    ],
    width: 600,
    height: 400,
};
const defaultTheme = createTheme({
    palette: {
        primary: {
            main:'#D34503'
        },
    },
});
export default function MyBarChart() {
    const [fetchTableData, { loading, error, data }] = useLazyQuery(BOULANGER);

    const [startDate, setStartDate] = useState(moment().startOf('month'))
    const [endDate, setEndDate] = useState(moment())



    useEffect(() => {
        fetchTableData({});
    }, [startDate, fetchTableData, endDate]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    let sellingPoints = data ? data.boulanger.sellingPoints : null;
    if(sellingPoints === null)
        return <p>Loading...</p>;

// Filtre les projets pour chaque sellingPoint
    const filteredSellingPoints = sellingPoints.map(sellingPoint => ({
        ...sellingPoint,
        projects: sellingPoint.projects.filter(project => {
            const projectDate = new Date(project.createdAt);
            return projectDate >= startDate.toDate() && projectDate <= endDate.toDate();
        })
    }));
    let bla = filteredSellingPoints.map((selling) => ({

            name : selling.name,
            count : selling.projects.length
        }))
    bla.sort((a, b) =>  a.count - b.count);
bla.reverse()
    return (
        <ThemeProvider theme={defaultTheme}>
            <Title>Evolution</Title>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de début"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            label="date de fin"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <div style={{width: '100%', flexGrow: 1}}>
                {
                    bla.length <=0 ? "":
                        <BarChart
                            margin={{left:150}}
                            dataset={bla}
                            yAxis={[{ scaleType: 'band', dataKey: 'name'}]}
                            series={[{ dataKey: 'count', label: 'Projets créé' }]}
                            layout="horizontal"
                            {...chartSetting}
                        />
                }
            </div>
        </ThemeProvider>
)

}