
import { gql } from '@apollo/client';

export const BOULANGER = gql`
query Boulanger{boulanger{sellingPoints{name projects{name createdAt updatedAt user {firstname surname} state{name}}}}}`


export const GETLASTMONTH = gql`
query getlast{getLastMonth{name updatedAt sellingPoint{name}}}`

export const GETTHISMONTH = gql`
query getThisMonth{getThisMonth{name updatedAt sellingPoint{name}}}`


export const CURRENT_USER_QUERY = gql`
query CurrentUserQuery {
  currentUser {
    id
    mail,
    password,
    role {name},
    user {
      id,
      firstname,
      surname,
      phone,
      post,
      SN
      projects{createdAt, name, state{name}}
      sellingPoint{name, company{name}, licences{headsets{serialNumber}}}
      }
  }
}
`;