import React, {useEffect, useState} from "react";
import {useLazyQuery, useQuery} from "@apollo/client";
import {BOULANGER} from "../graphql/queries";
import Grid from "@mui/material/Grid";
import {
    Container,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment"
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import 'moment/locale/fr';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
function ListMagasin()
{
    const [open, setOpen] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('month'))
    const [endDate, setEndDate] = useState(moment())
    const [company, setCompany] = useState([])
    const [fetchTableData, { data, loading, error }] = useLazyQuery(BOULANGER);

    const fetchData = (date) => {
        // Ici, vous pouvez récupérer ou filtrer les données en fonction de la date sélectionnée
        // Exemple : setTableData(filtrerDonnées(date));

    };

    useEffect(() => {
        fetchTableData({});
    }, [startDate, fetchTableData, endDate]);

    const handleDateChange = (date) => {
        setStartDate(date);
    };

    const handleOpen = (cellData) => {
        setSelectedCell(cellData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let tableData = data ? data.boulanger : [];
    return (

        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Point de vente
            </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <DatePicker
                    label="date de début"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                />

            </Grid>
            <Grid item xs={4}>
                <DatePicker
                    label="date de fin"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                />
            </Grid>
            <Grid item xs={10}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Nombre de Projets</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.sellingPoints ?  tableData.sellingPoints.map((cellData, index) => (
                                <TableRow key={index} onClick={() => handleOpen(cellData)}>

                                    <TableCell>
                                        {cellData.name}
                                    </TableCell>
                                    <TableCell>
                                        {cellData.projects.filter(project => {
                                            return project.createdAt.split('T')[0] >= startDate.format("YYYY-MM-DD") && project.createdAt.split('T')[0] <= endDate.format("YYYY-MM-DD") ;
                                        }).length}
                                    </TableCell>

                                </TableRow>
                            )) :" "}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2 id="modal-title">{selectedCell ? selectedCell.name : "" }</h2>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nom du Projet</TableCell>
                                    <TableCell>Date de Création</TableCell>
                                    <TableCell>Date de Mise à Jour</TableCell>
                                    <TableCell>État</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedCell ? selectedCell.projects.map((project, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{project.name}</TableCell>
                                        <TableCell>{project.createdAt}</TableCell>
                                        <TableCell>{project.updatedAt}</TableCell>
                                        <TableCell>{project.state.name}</TableCell>
                                    </TableRow>
                                )) : "ds"}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </Grid>
        </LocalizationProvider>
        </React.Fragment>
    )
}

export default ListMagasin