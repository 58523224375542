import Title from "./Title";
import {useMutation} from "@apollo/client";
import {LOGOUT} from "../graphql/mutation";
import {CURRENT_USER_QUERY} from "../graphql/queries";
import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";

export default function NoView()
{
    const defaultTheme = createTheme();
    function Logout() {
        let history = useNavigate();

        const [logout] = useMutation(LOGOUT, { refetchQueries: [{ query: CURRENT_USER_QUERY }], onCompleted(data){
                history(`/`)
            }});

        return (
            <Button variant="contained" onClick={() => logout()}>Se Déconnecter</Button>
        )
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Title>Vous n'avez les droits</Title>
        <Logout/>
                </Box>
            </Container>

        </ThemeProvider>

    )
}