import { Navigate } from "react-router-dom";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../graphql/queries";


const PrivateRoote = ({ Component }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { loading, error, data } = useQuery(CURRENT_USER_QUERY);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    setIsAuthenticated(data.currentUser != null)

    return isAuthenticated ? <Component /> : <Navigate to="/" replace={true}/>;
};
export default PrivateRoote;